import { permissoesEnum, entidadeTipoEnum } from "@/types/enums";

export default defineNuxtRouteMiddleware(() => {
  const { $ability } = useNuxtApp();

  if ($ability.can(permissoesEnum.VER_ROTA, "all")) {
    return;
  }

  // chegou aqui, não pode acessar a home, redireciona pra
  // primeira página que tiver acesso
  if (
    $ability.can(permissoesEnum.VER_ROTA, entidadeTipoEnum.REDE) ||
    $ability.can(permissoesEnum.VER_ROTA, entidadeTipoEnum.LOJA)
  ) {
    return navigateTo({
      name: "lojas",
    });
  }

  if ($ability.can(permissoesEnum.VER_ROTA, entidadeTipoEnum.SOCIO_HONORARIO)) {
    return navigateTo({
      name: "sh",
    });
  }

  return navigateTo({
    name: "usuarios",
  });
});
